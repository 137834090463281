import { useEffect, useState, useMemo } from "react";
import { useWatch } from "react-hook-form";
import {
    endsWith,
    every,
    find,
    forEach,
    get,
    has,
    isArray,
    isNumber,
    map,
    size,
    some,
    startsWith,
    includes,
    uniqBy,
    snakeCase,
} from "lodash";

import { getFieldName } from "~/utilities/formHelpers";

const checkConditionals = (
    fieldsWatched,
    fieldsToWatch,
    conditionalFieldsCollection,
    conditionalLogic,
    setShowField,
) => {
    const currentFieldValues = map(fieldsToWatch, (field, index) => {
        return {
            name: field,
            value: fieldsWatched[index],
        };
    });

    const evaluateConditional = ({ name, operator, value }) => {
        let passes = false;
        const currentValue = get(find(currentFieldValues, { name }), "value");
        const intValue = isNumber(currentValue);

        switch (operator) {
            case "is":
                passes = currentValue === value;
                break;
            case "isnot":
                passes = currentValue !== value && !!size(currentValue);
                break;
            case ">":
                if (intValue) {
                    passes = parseInt(currentValue, 10) > parseInt(value, 10);
                }
                break;
            case "<":
                if (intValue) {
                    passes = parseInt(currentValue, 10) < parseInt(value, 10);
                }
                break;
            case "contains":
                passes = includes(currentValue, value);
                break;
            case "starts_with":
                passes = startsWith(currentValue, value);
                break;
            case "ends_with":
                passes = endsWith(currentValue, value);
                break;
            default:
                break;
        }

        return passes;
    };

    const conditionalIsStrict = conditionalLogic.logicType === "all";
    let shouldShowField = true;

    if (conditionalIsStrict) {
        shouldShowField = every(
            conditionalFieldsCollection,
            evaluateConditional,
        );
    } else {
        shouldShowField = some(
            conditionalFieldsCollection,
            evaluateConditional,
        );
    }

    setShowField(shouldShowField);
};

const useFieldConditionalLogic = (
    formFields,
    conditionalLogic,
    formId,
    control,
) => {
    const [showField, setShowField] = useState(false);

    const { conditionalFieldsCollection, fieldsToWatch } = useMemo(() => {
        const conditionalFieldsCollection = [];
        let fieldsToWatch;

        if (
            conditionalLogic &&
            has(conditionalLogic, "rules") &&
            conditionalLogic.rules.length
        ) {
            forEach(conditionalLogic.rules, (rule) => {
                const fieldId = parseInt(rule.fieldId, 10);
                const fieldToWatch = find(formFields, { id: fieldId });
                const pardotField =
                    fieldToWatch.pardotField ?? snakeCase(fieldToWatch.label);
                const fieldName = fieldToWatch
                    ? getFieldName(pardotField, formId, fieldId)
                    : null;

                conditionalFieldsCollection.push({
                    name: fieldName,
                    operator: rule.operator,
                    value: rule.value,
                });
            });

            fieldsToWatch = map(
                uniqBy(conditionalFieldsCollection, "name"),
                "name",
            );
        }

        return {
            fieldsToWatch,
            conditionalFieldsCollection,
        };
    }, [formFields, conditionalLogic, formId]);

    const fieldsWatched = useWatch({
        control,
        name: fieldsToWatch,
    });

    useEffect(() => {
        if (
            checkConditionals &&
            conditionalLogic &&
            has(conditionalLogic, "rules") &&
            conditionalLogic.rules.length &&
            fieldsWatched &&
            isArray(fieldsWatched) &&
            fieldsWatched.length
        ) {
            checkConditionals(
                fieldsWatched,
                fieldsToWatch,
                conditionalFieldsCollection,
                conditionalLogic,
                setShowField,
            );
        } else {
            setShowField(true);
        }
    }, [
        fieldsWatched,
        fieldsToWatch,
        conditionalFieldsCollection,
        conditionalLogic,
        setShowField,
    ]);

    return showField;
};

export default useFieldConditionalLogic;
