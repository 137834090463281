import { isBrowser } from "~/utilities/helpers";
/**
 *
 * @param eventType
 * @param data
 */
const trackCustomEvent = (eventType, data) => {
    if (typeof window === "undefined") {
        return;
    }
    window.dataLayer = window.dataLayer || [];

    let trackingPayload = {};
    if (!eventType) {
        if (isBrowser) {
            console.error("eventType is required");
        }
        return;
    }
    trackingPayload.event = eventType;
    if (data.action) {
        trackingPayload.eventAction = data.action;
    }
    if (data.category) {
        trackingPayload.eventCategory = data.category;
    }
    if (data.label) {
        trackingPayload.eventLabel = data.label;
    }
    if (data.value) {
        trackingPayload.eventValue = data.value;
    }
    if (data.additionalTracking) {
        trackingPayload = { ...trackingPayload, ...data.additionalTracking };
    }
    window.dataLayer.push(trackingPayload);
};

export default trackCustomEvent;

export const trackCustomGA4Event = (eventName, data = {}) => {
    if (!isBrowser) {
        return;
    }

    window.dataLayer = window.dataLayer || [];

    if (!eventName) {
        console.error("eventName is required");
    }

    window.dataLayer.push({
        event: eventName,
        ...data,
    });
};
